import { ChevronDown } from 'lucide-react';
// Import the Message component
import LandingButton from '@/components/ui/OldButton/LandingButton';
import AnimatedGradientBackground from './AnimatedGradient';

export default function LandingHero() {
  return (
    <div>
      <div className="relative z-20  ">
        <div className="z-30 relative">
          <div className="content-default  py-40 z-20 relative max-w-7xl min-h-[50vh] ">
            <div className="h-full flex flex-col  items-center justify-end md:justify-center ">
              <div className=" text-center">
                <div className="">
                  <h2 className="text-4xl leading-tight md:text-[4.4rem] font-medium md:leading-[4.8rem] mb-1 md:mb-5 tracking-tighter text-transparent bg-clip-text bg-gradient-to-b from-neutral-700 to-neutral-900 dark:from-neutral-50 dark:to-neutral-200 pb-2 ">
                    Intelligence
                    <br />
                    Working For You
                  </h2>

                  <div className=" mx-auto">
                    <p className="lp w-full  mb-10 px-5">
                      We’re transforming the business and investing world by
                      integrating data intelligence, automation, and AI into a
                      single platform anyone can use.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-2">
                <LandingButton href="/signup" title={'Get Started'} />
                <LandingButton
                  href="/contact"
                  title={'Get A Demo'}
                  arrow={false}
                  style="secondary"
                />
              </div>
              <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 opacity-50">
                <ChevronDown className="w-8 h-8 text-primary" strokeWidth={1} />
              </div>
            </div>
          </div>
        </div>
        <AnimatedGradientBackground />
      </div>
    </div>
  );
}
