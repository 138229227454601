import { BarChart4, Radar, UserSearch } from 'lucide-react';
import FeatureBlock from '../../utils/FeatureBlock';
import SmartList from '../../../Marketing/SmartList';

export default function Subhead() {
  const isBrowser = typeof window !== 'undefined';
  return (
    <div className="relative ">
      <div className="py-5 content-default ">
        <div className="mx-auto max-w-7xl   grid grid-cols-1 md:grid-cols-2 p-6">
          <div className=" w-full pt-10   flex flex-col justify-center">
            <div>
              <h2 className="text-3xl font-medium leading-tight tracking-tighter text-primary sm:text-3xl">
                Find Companies
              </h2>
              <p className="mt-2 mb-5  opacity-80">
                Finding companies is a time-consuming process. No matter if
                you're looking for sales targets, investment opportunities, or
                potential partners, it's a process that requires a lot of time
                and effort. With our autonomous screener, you can automate the
                process of finding companies, saving you time and effort.
              </p>
              <FeatureBlock
                features={[
                  { name: 'Radar Agent ', icon: Radar },
                  { name: 'Financials', icon: BarChart4 },
                  { name: 'People Search', icon: UserSearch }
                ]}
              />
            </div>
          </div>
          <div className=" w-full pt-10  gap-x-5 max-w-md mx-auto">
            <div className="  relative overflow-clip bg-main-background">
              <div className="border border-line rounded-lg pb-10 h-[20rem] overflow-hidden">
                <SmartList />
              </div>
              <div className="absolute w-full  bottom-0 px-1">
                <div className="absolute left-0 w-full bottom-0 h-20 bg-gradient-to-b from-transparent to-landing-neutral  pointer-events-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
