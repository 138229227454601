import excel from '@/components/icons/Excel';
import { MessagesSquare, PackageCheck, Workflow } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { animated, useTrail } from 'react-spring';
import { useDebouncedCallback } from 'use-debounce';
import FeatureBlock from '../../utils/FeatureBlock';
import Message from '../Message';

const data = [
  {
    title: 'Research Startups',
    messages: [
      {
        msg: 'Find newly registered AI startups in the US, including founders, funding info, and contact emails.',
        reply: false
      },
      {
        msg: 'Sure. Gathering that info now, I will notify you once it’s ready...',
        reply: true
      },
      { wait: 1000 },
      {
        msg: 'Here is the list. Download the spreadsheet below:',
        reply: true
      },
      { filename: 'us_ai_startups.xls', icon: excel, reply: true }
    ]
  },
  {
    title: 'Analyze Financials',
    messages: [
      {
        msg: 'Compile the latest SEC filings for Apple and Google, and summarize key financial highlights.',
        reply: false
      },
      {
        msg: 'Understood. Pulling 10-Ks and 10-Qs now. Will let you know once done.',
        reply: true
      },
      { wait: 1000 },
      {
        msg: 'All set. You can review the summarized data below:',
        reply: true
      },
      { filename: 'financial_summary.xls', icon: excel, reply: true }
    ]
  },
  {
    title: 'Competitor Overview',
    messages: [
      {
        msg: 'List Zoom’s top competitors in video conferencing with market share estimates.',
        reply: false
      },
      {
        msg: 'No problem. Gathering competitor data from multiple sources...',
        reply: true
      },
      { wait: 1000 },
      {
        msg: 'Analysis complete. Here’s your spreadsheet:',
        reply: true
      },
      { filename: 'zoom_competitors.xls', icon: excel, reply: true }
    ]
  },
  {
    title: 'Investment Targets',
    messages: [
      {
        msg: 'Find private EdTech companies valued over $100M, focusing on North America and Europe.',
        reply: false
      },
      {
        msg: 'Got it. I will compile the valuations, locations, and major investors...',
        reply: true
      },
      { wait: 1000 },
      {
        msg: 'Done. Feel free to download your list:',
        reply: true
      },
      { filename: 'edtech_investment_targets.xls', icon: excel, reply: true }
    ]
  }
];

export default function LandingHero() {
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [title, setTitle] = useState(data[0].title);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTitle(data[currentInteractionIndex].title);
    setMessages([]);
    const interactionMessages = data[currentInteractionIndex].messages;

    const showMessages = async () => {
      for (let i = 0; i < interactionMessages.length; i++) {
        const message = interactionMessages[i];
        setMessages((prev) => [...prev, message]);
        // Default wait is 1500ms if not specified
        await new Promise((resolve) =>
          setTimeout(resolve, message.wait || 1500)
        );
      }
      // Wait before switching to the next set
      setTimeout(() => {
        setCurrentInteractionIndex((prev) => (prev + 1) % data.length);
      }, 3000);
    };

    showMessages();
  }, [currentInteractionIndex]);

  const trail = useTrail(messages.length, {
    from: { opacity: 0, transform: 'translateY(10px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 400 }
  });

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const debouncedScrollToBottom = useDebouncedCallback(() => {
    scrollToBottom();
  }, 50);

  useEffect(() => {
    debouncedScrollToBottom();
  }, [trail, debouncedScrollToBottom]);

  return (
    <div className="py-5 content-default">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-10 p-6">
        <div className="w-full max-w-md mx-auto ">
          <div className="relative overflow-hidden shadow-xl shadow-black/5 border border-line rounded-xl bg-main-background">
            <div
              className="relative overflow-y-scroll scrollbar-none px-5 pt-5 pb-20 h-72"
              ref={containerRef}
            >
              {trail.map((style, index) => (
                <animated.div key={index} style={style}>
                  <Message
                    key={index}
                    item={messages[index]}
                    isLast={index === messages.length - 1}
                  />
                </animated.div>
              ))}
              <div ref={messagesEndRef} style={{ height: 10 }} />
            </div>
          </div>
        </div>
        <div className="w-full pt-10 flex flex-col justify-center">
          <h2 className="text-3xl font-medium leading-tight tracking-tighter text-primary sm:text-3xl">
            Delegate Research <br />
            And Data Collection
          </h2>
          <p className="mt-2 mb-5 opacity-80">
            Meet Blair, your personal analyst who can gather insights, compile
            lists, research financials, and more.
          </p>
          <FeatureBlock
            features={[
              { name: 'Chat Based', icon: MessagesSquare },
              { name: 'Excel Deliverables', icon: PackageCheck },
              { name: 'Workflows', icon: Workflow }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
