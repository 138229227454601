import { Tables } from '@/types/supabase';
import { supabase } from '@/utils/supabase-client';
import { QueryData, RealtimeChannel } from '@supabase/supabase-js';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';

const listWithSymbolsQuery = supabase.from('msh_lists').select('*').single();

export type ListsWithSymbols = QueryData<typeof listWithSymbolsQuery> & {
  msh_lists_symbols?: Tables<'msh_lists_symbols'>[];
  msh_lists_requests?: Tables<'msh_lists_requests'>[];
  symbolsLoading?: boolean;
  requestsLoading?: boolean;
};
const store = proxy({
  loading: true,
  items: [] as ListsWithSymbols[],
  subscriptions: {
    enabled: false,
    channels: [] as RealtimeChannel[]
  },

  enableSubscriptions: async () => {
    if (store.subscriptions.enabled) return;
    console.log('subscribing to channels');

    const listsChannel = supabase
      .channel('lists-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'msh_lists'
        },
        (payload) => store.handleRealtimeUpdate(payload as any)
      )
      .subscribe();

    const symbolsChannel = supabase
      .channel('symbols-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'msh_lists_symbols'
        },
        (payload) => store.handleRealtimeSymbol(payload as any)
      )
      .subscribe();

    const requestsChannel = supabase
      .channel('requests-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'msh_lists_requests'
        },

        (payload) => store.handleRealtimeRequest(payload as any)
      )
      .subscribe();

    store.subscriptions.channels.push(
      listsChannel,
      symbolsChannel,
      requestsChannel
    );
    store.subscriptions.enabled = true;
  },

  disableSubscriptions: async () => {
    if (!store.subscriptions.enabled) return;

    // Unsubscribe from each channel individually
    for (const channel of store.subscriptions.channels) {
      if (channel) {
        try {
          await channel.unsubscribe();
        } catch (error) {
          console.error('Error unsubscribing from channel:', error);
        }
      }
    }
    store.subscriptions.channels = [];
    store.subscriptions.enabled = false;
  },

  loadSymbolsForList: async (listId: string) => {
    const existingItem = store.items.find((item) => item.id === listId);
    if (!existingItem || existingItem.msh_lists_symbols) return;

    existingItem.symbolsLoading = true;

    try {
      const { error, data } = await supabase
        .from('msh_lists_symbols')
        .select('*')
        .eq('msh_symbol_list_id', listId)
        .is('deleted_at', null);

      if (error) {
        console.error('Error loading symbols:', error);
      } else {
        existingItem.msh_lists_symbols = data || [];
      }
    } finally {
      existingItem.symbolsLoading = false;
    }
  },

  loadRequestsForList: async (listId: string) => {
    const existingItem = store.items.find((item) => item.id === listId);
    if (!existingItem || existingItem.msh_lists_requests) return;

    existingItem.requestsLoading = true;

    try {
      const { error, data } = await supabase
        .from('msh_lists_requests')
        .select('*')
        .in('status', ['pending', 'processing'])
        // not older than 2 days
        .eq('msh_symbol_list_id', listId);

      if (error) {
        console.error('Error loading requests:', error);
      } else {
        existingItem.msh_lists_requests = data || [];
      }
    } finally {
      existingItem.requestsLoading = false;
    }
  },

  addRequest: async (
    listId: string,
    requestId: string,
    query: string,
    options: Partial<Tables<'msh_lists_requests'>> = {}
  ) => {
    const existingItem = store.getItemById(listId);
    if (!existingItem) {
      console.log('List not found');
      return;
    }

    // Load requests if they haven't been loaded yet
    if (!existingItem.msh_lists_requests) {
      await store.loadRequestsForList(listId);
    }

    // Initialize requests array if needed
    if (!existingItem.msh_lists_requests) {
      existingItem.msh_lists_requests = [];
    }

    // Check if request already exists
    if (
      existingItem.msh_lists_requests.some(
        (request) => request.request_id === requestId && request.query === query
      )
    ) {
      console.log('Request already exists in the list');
      return;
    }

    const { data, error } = await supabase
      .from('msh_lists_requests')
      .upsert(
        {
          msh_symbol_list_id: listId,
          request_id: requestId,
          query,
          status: 'pending',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          ...options
        },
        { onConflict: 'msh_symbol_list_id,query' }
      )
      .select('*');

    if (error) {
      console.error('error', error);
      return;
    }

    const item = data?.[0];
    if (item) {
      existingItem.msh_lists_requests.push(item);
    }
  },

  removeRequest: async (listId: string, requestId: string, query: string) => {
    const existingItem = store.getItemById(listId);
    if (!existingItem) {
      console.log('List not found');
      return;
    }

    // Load requests if they haven't been loaded yet
    if (!existingItem.msh_lists_requests) {
      await store.loadRequestsForList(listId);
    }

    if (!existingItem.msh_lists_requests) {
      console.log('Requests not initialized');
      return;
    }

    const { error } = await supabase
      .from('msh_lists_requests')
      .delete()
      .eq('msh_symbol_list_id', listId)
      .eq('request_id', requestId)
      .eq('query', query);

    if (!error) {
      existingItem.msh_lists_requests = existingItem.msh_lists_requests.filter(
        (request) =>
          !(request.request_id === requestId && request.query === query)
      );
    }
  },

  addTicker: async (id, ticker) => {
    const existingItem = store.getItemById(id);
    if (!existingItem) {
      console.log('List not found');
      return;
    }

    // Load symbols if they haven't been loaded yet
    if (!existingItem.msh_lists_symbols) {
      await store.loadSymbolsForList(id);
    }

    // Initialize symbols array if needed (shouldn't be needed after loadSymbolsForList)
    if (!existingItem.msh_lists_symbols) {
      existingItem.msh_lists_symbols = [];
    }

    // Check if ticker already exists
    if (
      existingItem.msh_lists_symbols.some((symbol) => symbol.msh_id === ticker)
    ) {
      console.log('Ticker already exists in the list');
      return;
    }

    const { data, error } = await supabase
      .from('msh_lists_symbols')
      .upsert(
        {
          msh_id: ticker,
          msh_symbol_list_id: id as string,
          status: 'done',
          deleted_at: null
        },
        { onConflict: 'msh_id,msh_symbol_list_id' }
      )
      .select('*');

    if (error) {
      console.error('error', error);
      return;
    }

    const item = data?.[0];
    if (item) {
      existingItem.msh_lists_symbols.push(item);
    }
  },

  removeTicker: async (id, ticker) => {
    if (!id || !ticker) return;

    const existingItem = store.getItemById(id);
    if (!existingItem) {
      console.log('List not found');
      return;
    }

    // Load symbols if they haven't been loaded yet
    if (!existingItem.msh_lists_symbols) {
      await store.loadSymbolsForList(id);
    }

    if (!existingItem.msh_lists_symbols) {
      console.log('Symbols not initialized');
      return;
    }

    const { error } = await supabase
      .from('msh_lists_symbols')
      .delete()
      .eq('msh_id', ticker);

    if (!error) {
      existingItem.msh_lists_symbols = existingItem.msh_lists_symbols.filter(
        (stock) => stock.msh_id !== ticker
      );
    }
  },

  loadInitial: async () => {
    const { error, data } = await supabase
      .from('msh_lists')
      .select('*')
      .is('deleted_at', null)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('error', error);
    } else {
      store.mergeItems(
        data.map((item) => ({
          ...item,
          msh_lists_symbols: undefined,
          symbolsLoading: false
        }))
      );
    }
    store.loading = false;
  },

  getItemById(id) {
    const item = this.items.find((item) => item.id === id);
    if (item && !item.msh_lists_symbols && !item.symbolsLoading) {
      // Trigger symbol loading in the background
      store.loadSymbolsForList(id);
    }
    return item;
  },

  delete: async (id) => {
    const { error } = await supabase
      .from('msh_lists')
      .update({ deleted_at: new Date().toISOString() })
      .eq('id', id);

    if (error) {
      console.error('error', error);
      return;
    }
    store.items = store.items.filter((item) => item.id !== id);
  },

  update: async (id, updatedData, persist = false) => {
    if (persist) {
      const { error } = await supabase
        .from('msh_lists')
        .update(updatedData)
        .eq('id', id);

      if (error) {
        console.error('error', error);
        return;
      }
    }

    store.items = store.items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...updatedData,
          msh_lists_symbols:
            updatedData.msh_lists_symbols || item.msh_lists_symbols
        };
      }
      return item;
    });
  },

  setItems(items) {
    this.items = items.map((item) => ({
      ...item,
      msh_lists_symbols: undefined,
      symbolsLoading: false
    }));
  },

  addItem(item) {
    const existingItemIndex = this.items.findIndex(
      (existingItem) => existingItem.id === item.id
    );

    if (existingItemIndex !== -1) {
      this.items[existingItemIndex] = {
        ...this.items[existingItemIndex],
        ...item,
        msh_lists_symbols:
          item.msh_lists_symbols ||
          this.items[existingItemIndex].msh_lists_symbols
      };
    } else {
      this.items.push({
        ...item,
        msh_lists_symbols: undefined,
        symbolsLoading: false
      });
    }
  },

  mergeItems(newItems) {
    newItems.forEach((item) => this.addItem(item));
  },

  handleRealtimeRequest: (payload: {
    eventType: 'INSERT' | 'UPDATE' | 'DELETE';
    new: Tables<'msh_lists_requests'>;
    old: { msh_symbol_list_id: string; request_id: string };
  }) => {
    const listId = payload.new.msh_symbol_list_id;
    const list = store.items.find((item) => item.id === listId);
    if (!list || !list.msh_lists_requests) return;

    switch (payload.eventType) {
      case 'INSERT':
        if (
          !list.msh_lists_requests.some(
            (request) =>
              request.msh_symbol_list_id === payload.new.msh_symbol_list_id &&
              request.request_id === payload.new.request_id &&
              request.query === payload.new.query
          )
        ) {
          list.msh_lists_requests.push(payload.new);
        }
        break;
      case 'UPDATE':
        const existingIndex = list.msh_lists_requests.findIndex(
          (request) =>
            request.msh_symbol_list_id === payload.new.msh_symbol_list_id &&
            request.request_id === payload.new.request_id &&
            request.query === payload.new.query
        );

        if (existingIndex !== -1) {
          list.msh_lists_requests[existingIndex] = {
            ...list.msh_lists_requests[existingIndex],
            ...payload.new
          };
        } else {
          list.msh_lists_requests.push(payload.new);
        }
        break;
      case 'DELETE':
        list.msh_lists_requests = list.msh_lists_requests.filter(
          (request) =>
            !(
              request.msh_symbol_list_id === payload.old.msh_symbol_list_id &&
              request.request_id === payload.old.request_id
            )
        );
        break;
    }
  },

  handleRealtimeUpdate: (payload: {
    eventType: 'INSERT' | 'UPDATE' | 'DELETE';
    new: ListsWithSymbols;
    old: { id: string };
  }) => {
    switch (payload.eventType) {
      case 'INSERT':
        if (!store.items.some((item) => item.id === payload.new.id)) {
          store.addItem({
            ...payload.new,
            msh_lists_symbols: undefined,
            symbolsLoading: false
          });
        }
        break;
      case 'UPDATE':
        store.update(payload.new.id, { ...payload.new }, false);
        break;
      case 'DELETE':
        store.items = store.items.filter((item) => item.id !== payload.old.id);
        break;
    }
  },

  handleRealtimeSymbol: (payload: {
    eventType: 'INSERT' | 'UPDATE' | 'DELETE';
    new: Tables<'msh_lists_symbols'>;
    old: { id: string };
  }) => {
    const listId = payload.new.msh_symbol_list_id;
    const list = store.items.find((item) => item.id === listId);
    if (!list || !list.msh_lists_symbols) return;

    switch (payload.eventType) {
      case 'INSERT':
        if (
          !list.msh_lists_symbols.some((symbol) => symbol.id === payload.new.id)
        ) {
          list.msh_lists_symbols.push(payload.new);
        }
        break;
      case 'UPDATE':
        const existingIndex = list.msh_lists_symbols.findIndex(
          (symbol) => symbol.id === payload.new.id
        );

        if (existingIndex !== -1) {
          list.msh_lists_symbols[existingIndex] = {
            ...list.msh_lists_symbols[existingIndex],
            ...payload.new
          };
        } else {
          list.msh_lists_symbols.push(payload.new);
        }
        break;
      case 'DELETE':
        list.msh_lists_symbols = list.msh_lists_symbols.filter(
          (symbol) => symbol.id !== payload.old.id
        );
        break;
    }
  }
});

// Update the useLists hook
export function useLists(preloadedList?: Tables<'msh_lists'>) {
  const snapshot = useSnapshot(store);

  useEffect(() => {
    if (preloadedList) {
      store.addItem(preloadedList);
      store.loading = false;
    }
  }, [preloadedList?.id]);

  return {
    ...snapshot,
    enableSubscriptions: store.enableSubscriptions,
    disableSubscriptions: store.disableSubscriptions
  };
}
export default store;
