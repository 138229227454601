import React from 'react';
import { Newspaper, Search, Workflow } from 'lucide-react';
import FeatureBlock from '../../utils/FeatureBlock';

// AgentExample Component
const AgentExample = () => (
  <div className="space-y-4">
    <div className="border rounded-lg p-4  ">
      <div className="flex items-center space-x-3">
        <div>
          <h4 className="font-medium">Research Agent</h4>
          <p className="text-sm text-gray-500">Gathering market insights...</p>
        </div>
      </div>
      <div className="mt-3 text-sm">
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 bg-green-500 rounded-full" />
          <span>Scanning competitor websites</span>
        </div>
        <div className="flex items-center space-x-2 mt-2">
          <div className="w-2 h-2 bg-blue-500 rounded-full" />
          <span>Analyzing market trends</span>
        </div>
      </div>
    </div>
    <div className="border rounded-lg p-4  ">
      <div className="flex items-center space-x-3">
        <Workflow className="w-6 h-6 " />
        <div>
          <h4 className="font-medium">Task Agent</h4>
          <p className="text-sm text-gray-500">Processing data feeds...</p>
        </div>
      </div>
      <div className="mt-3 text-sm">
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 bg-yellow-500 rounded-full" />
          <span>Monitoring API endpoints</span>
        </div>
      </div>
    </div>
  </div>
);

// Main Component
export default function Subhead() {
  return (
    <div className="relative">
      <div className="py-5 content-default">
        <div className="mx-auto max-w-7xl grid md:grid-cols-2 grid-cols-1 p-6">
          <div className="w-full pt-10">
            <div>
              <h2 className="text-3xl font-medium leading-tight tracking-tighter text-primary sm:text-3xl">
                Automate Your <br />
                Research & Analysis
              </h2>
              <p className="mt-2 mb-8 opacity-80">
                Deploy AI agents that continuously gather insights and monitor
                data sources. Let intelligent agents handle repetitive tasks
                while you focus on strategic decisions. No coding required -
                just define what you need and launch your agent.
              </p>
              <FeatureBlock
                features={[
                  { name: 'Web Research', icon: Search },
                  { name: 'Task Automation', icon: Workflow },
                  { name: 'News Monitoring', icon: Newspaper }
                ]}
              />
            </div>
          </div>
          <div className="relative overflow-clip">
            <div className="border border-line rounded-lg pb-5 px-5 pt-5 h-full min-h-[10rem] max-w-md mx-auto panel">
              <AgentExample />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
