import TickerLink from '@/components/ui/Elements/TickerLink';
import { RealtimeQuote, TickerType } from '@/types/frontend/page';
import {
  formatAbsoluteChange,
  formatPercent,
  formatPrice
} from '@/utils/numberFormat';
import { useRouter } from 'next/router';
import { Fragment, memo, useState } from 'react';
import QuickFollowButton from './QuickFollowButton';
import cn from 'classnames';
import { useTickerData } from '@/hooks/useTickers';
import { ArrowDown, ArrowUp, X } from 'lucide-react';
import listsStore from '@/store/list';

export enum TickerTableMode {
  Manual = 'manual',
  Gainers = 'gainers',
  Losers = 'losers',
  Movers = 'movers'
}
export interface TickerTableProps {
  resultsCount?: number;
  tickerType?: TickerType;
  minMktCap?: number;
  maxMktCap?: number;
  tickers?: string[];
  showVolume?: boolean;
  initialData?: RealtimeQuote[];
  showPercentage?: boolean;
  withPanelStyles?: boolean;
  small?: boolean;
  showQuickFollow?: boolean;
  watchlistMode?: boolean;
  collapseAfter?: number;
  market?: string;
  prefetch?: boolean;
  showQuotes?: boolean;
  showPrice?: boolean;
  listId?: string;
}

const TickerTable = ({
  listId,
  showPercentage = true,
  withPanelStyles = true,
  small = false,
  showQuickFollow = true,
  watchlistMode = false,
  collapseAfter,
  prefetch = false,
  showQuotes = true,
  tickers = [],
  showPrice = true
}: TickerTableProps) => {
  const { tickerData } = useTickerData(tickers);
  const [maxItems, setMaxItems] = useState<number | undefined>(collapseAfter);

  const router = useRouter();

  return (
    <div
      className={` w-full scrollbar-none z-10 ${
        withPanelStyles ? 'panel' : ''
      }`}
    >
      <ul className="panel-rounded  overflow-clip">
        {tickers?.length > 0 && tickerData?.[tickers[0]]?.name
          ? tickers.slice(0, maxItems).map((li, idx) => {
              const tickDirectionBg =
                tickerData[li]?.change_perc_today === 0
                  ? 'bg-line'
                  : tickerData[li]?.change_perc_today > 0
                    ? 'bg-change-color-green'
                    : 'bg-change-color-red';
              const tickDirectionColor =
                tickerData[li]?.change_perc_today === 0
                  ? 'line'
                  : tickerData[li]?.change_perc_today > 0
                    ? 'change-color-green'
                    : 'change-color-red';
              return (
                // <Link
                //   key={`watchlist-table-entry-${li.symbol}`}
                //   passHref
                //   href={`/symbols/${li.msh_id}`}
                // >

                <li
                  // onClick={() => {
                  //   router.push(`/symbols/${li.msh_id}`);
                  // }}
                  key={`tti-${tickerData[li]?.msh_id}-${idx}`}
                  className={`flex justify-between items-center py-2  hover:bg-zinc-400/10 ${
                    router.asPath == `/symbols/${tickerData[li]?.msh_id}`
                      ? 'bg-zinc-400/5 hover:bg-zinc-400/10  border-r-2  border-blue-600'
                      : 'border-r-2 border-transparent'
                  } ${!small ? 'odd:bg-accents-3' : 'odd:bg-accents-3'}`}
                >
                  <TickerLink
                    msh_id={tickerData[li]?.msh_id}
                    className="pl-2 md:px-3  text-sm h-full flex min-w-0"
                    small={small}
                    prefetch={prefetch}
                  />
                  {small && (
                    <div
                      className={cn(
                        'h-1 w-1 right-1.5 absolute rounded-full transition-all ease-in-out ',
                        tickDirectionBg,
                        {
                          'opacity-0 delay-0': showQuotes,
                          'opacity-100 delay-200 ': !showQuotes
                        }
                      )}
                    ></div>
                  )}
                  <div
                    className={cn(
                      'flex flex-row h-full shrink-0 transition-all ease-in-out ',
                      {
                        'opacity-0 delay-0': !showQuotes,
                        'opacity-100 delay-300 ': showQuotes,
                        'pr-2': small,
                        'pr-3': !small
                      }
                    )}
                  >
                    {showPrice && tickerData[li]?.market == 'STOCKS' && (
                      <div
                        className={`${
                          !small ? '' : ''
                        } py-0 whitespace-nowrap text-primary tabular-nums text-right min-w-0 space-y-0.5 leading-tight text-xs flex flex-col justify-between test-ignore `}
                      >
                        <span
                          className={`text-left text-xs text-${tickDirectionColor} `}
                        >
                          <span className={`font-semibold dark:font-medium`}>
                            {formatAbsoluteChange(
                              tickerData[li]?.last_price -
                                tickerData[li]?.last_close
                            )}
                          </span>
                          {showPercentage &&
                            tickerData[li]?.change_perc_today != 0 && (
                              <span
                                className={`font-semibold dark:font-medium`}
                              >
                                {' '}
                                (
                                {formatPercent(
                                  tickerData[li]?.change_perc_today,
                                  true
                                )}
                                )
                              </span>
                            )}
                        </span>

                        <span className="font-bold dark:font-semibold text-right test-ignore">
                          {formatPrice(tickerData[li]?.last_price)}
                        </span>
                      </div>
                    )}
                    {!listId && showQuickFollow && !small && (
                      <div className="pl-3 ">
                        <QuickFollowButton
                          msh_id={tickerData[li]?.msh_id}
                          last_price={tickerData[li]?.last_price}
                          watchlistMode={watchlistMode}
                        />
                      </div>
                    )}
                    {listId && (
                      <button
                        className="opacity-20 hover:opacity-100  hover:text-red-500 h-full cursor-pointer pl-5 pt-1.5 "
                        onClick={() =>
                          // onRemove(header?.getContext()?.header?.id)
                          listsStore.removeTicker(
                            listId,
                            tickerData[li]?.msh_id
                          )
                        }
                      >
                        <X size={18} className="cursor-pointer" />
                      </button>
                    )}
                  </div>
                </li>
              );
            })
          : Array.from({ length: tickers?.length }).map(
              (undefined, i: number) => (
                <li
                  // onClick={() => {
                  //   router.push(`/symbols/${li.msh_id}`);
                  // }}
                  key={`ticker-t-load-${i}`}
                  className={`flex justify-between items-center py-2  hover:bg-zinc-400/10  ${
                    !small ? 'odd:bg-accents-3' : 'odd:bg-accents-3'
                  }`}
                >
                  <TickerLink
                    className="pl-2 md:px-3  text-sm h-full flex min-w-0	 shrink"
                    small={small}
                    msh_id="loading"
                  />
                </li>
              )
            )}
      </ul>
      {collapseAfter && collapseAfter < Object.keys(tickerData).length && (
        <div className="flex items-center justify-center py-3">
          <button
            className="text-sm flex flex-row text-blue-500"
            onClick={() =>
              maxItems ? setMaxItems(undefined) : setMaxItems(collapseAfter)
            }
          >
            {maxItems ? (
              <Fragment>
                <ArrowDown className="mr-1" />
                Show More
              </Fragment>
            ) : (
              <Fragment>
                <ArrowUp className="mr-1" />
                Show Less
              </Fragment>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
export default memo(TickerTable);
