import FindingCompanies from './FeatureBlocks/FindingCompanies';
import Signals from './FeatureBlocks/Signals';
import Chat from './FeatureBlocks/Chat';

export default function Features() {
  return (
    <div className=" flex-col  landing-page pt-10 pb-10 relative hidden md:flex">
      <div className="text-center pb-10">
        <h2 className="lp mb-4">
          Powerful Tools <br /> Driving Your Workflow
        </h2>
        <p className="lp px-5">
          You don’t need to be a data scientist to embrace a data-driven
          approach and reap the benefits of AI. Our user-friendly platform puts
          powerful tools in your hands, allowing you to get started in minutes.
        </p>
      </div>
      <FindingCompanies />
      <Chat />
      <Signals />
    </div>
  );
}
