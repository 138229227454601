import LandingButton from '../../OldButton/LandingButton';

type Props = {
  topText?: string;
  bottomText?: string;
  buttonText?: string;
  secondaryButtonText?: string;
};

export default function FooterCta({
  topText = 'The Future of Research',
  bottomText = 'Available Today',
  buttonText = 'Get Started',
  secondaryButtonText = 'Get In Touch'
}: Props) {
  return (
    <div className="content-default flex flex-col justify-center pb-0 relative z-10 mt-20">
      <h2 className="text-center text-3xl md:text-5xl font-medium tracking-tighter leading-tight text-transparent bg-clip-text bg-gradient-to-b from-neutral-600 to-neutral-800 dark:from-neutral-100 dark:to-neutral-300">
        {topText}
        <br />
        {bottomText}
      </h2>
      <div className="flex gap-x-2 mx-auto mt-10">
        <LandingButton href="/signup" title={buttonText} />
        <LandingButton
          href="/contact"
          title={secondaryButtonText}
          arrow={false}
          style="secondary"
        />
      </div>
    </div>
  );
}
