import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Database,
  Globe,
  MessageSquare,
  Building2,
  ChartLine,
  Newspaper,
  Brain,
  Network
} from 'lucide-react';

const DashboardLayout = () => {
  return (
    <div className="relative">
      <div className="p-6 max-w-7xl mx-auto relative">
        <div className="text-center pb-20 max-w-xl mx-auto">
          <h2 className="lp">Financial Intelligence Platform</h2>
          <p className="lp ">
            Access comprehensive financial data, real-time market updates, and
            private company intelligence enhanced by AI-powered insights. Query
            complex data through natural language or integrate via our API.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Financial Database Card */}
          <Card className="col-span-1 lg:col-span-2">
            <CardHeader>
              <div className="flex items-center gap-2">
                <Database className="h-5 w-5" />
                <CardTitle>Financial Database</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">
                Comprehensive financial data covering global markets, companies,
                and assets
              </p>
              <div className="mt-8 space-y-2">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">
                    Historical market data across asset classes
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">
                    Company fundamentals and financials
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">
                    Economic indicators and analytics
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Real-time News API Card */}
          <Card>
            <CardHeader>
              <div className="flex items-center gap-2">
                <Newspaper className="h-5 w-5" />
                <CardTitle>Real-time News API</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">
                Live news and sentiment analysis from global sources
              </p>
              <div className="mt-4 space-y-2">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">Market-moving news alerts</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">Sentiment analysis</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                    ✓
                  </div>
                  <span className="text-sm">Custom news filters</span>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* LLM Support Card */}
          <Card>
            <CardHeader>
              <div className="flex items-center gap-2">
                <Brain className="h-5 w-5" />
                <CardTitle>AI & LLM Integration</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">
                Natural language interface for complex financial queries
              </p>
              <div className="mt-4 space-y-2">
                <div className="flex items-center gap-2">
                  <MessageSquare className="h-4 w-4" />
                  <span className="text-sm">Natural language queries</span>
                </div>
                <div className="flex items-center gap-2">
                  <ChartLine className="h-4 w-4" />
                  <span className="text-sm">Automated analysis</span>
                </div>
                <div className="flex items-center gap-2">
                  <Globe className="h-4 w-4" />
                  <span className="text-sm">Multi-source integration</span>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Private Company Intelligence Card */}
          <Card className="col-span-1 lg:col-span-1">
            <CardHeader>
              <div className="flex items-center gap-2">
                <Building2 className="h-5 w-5" />
                <CardTitle>Private Company Intelligence</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">
                Deep insights into private companies and markets
              </p>
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                      ✓
                    </div>
                    <span className="text-sm">Ownership structures</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                      ✓
                    </div>
                    <span className="text-sm">Financial metrics</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                      ✓
                    </div>
                    <span className="text-sm">Market positioning</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 text-emerald-500 flex items-center justify-center">
                      ✓
                    </div>
                    <span className="text-sm">Growth indicators</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* API Integration Card */}
          <Card>
            <CardHeader>
              <div className="flex items-center gap-2">
                <Network className="h-5 w-5" />
                <CardTitle>API Integration</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">
                Seamless API access to all platform features
              </p>
              <div className="mt-4 space-y-2">
                {[
                  { name: 'Symbol Search', route: '/v1/symbols/search' },
                  { name: 'News Feed', route: '/v1/news' },
                  {
                    name: 'Symbol Details',
                    route: '/v1/symbols/NASDAQ:AAPL'
                  },
                  {
                    name: 'Market Quotes',
                    route: '/v1/symbols/NASDAQ:AAPL/quotes'
                  },
                  { name: 'User Lists', route: '/v1/u/lists' }
                ].map((api) => (
                  <div
                    key={api.route}
                    className="flex items-center justify-between"
                  >
                    <span className="text-sm">{api.name}</span>
                    <span className="text-sm text-muted-foreground">
                      {api.route}
                    </span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
