import React from 'react';

const AnimatedGradient = ({
  color1 = '#6aff00', // Green default
  color2 = '#00a2ff', // Light blue default
  color3 = '#413ace' // Dark blue default
}) => {
  return (
    <div className="absolute inset-0 w-full h-full overflow-hidden opacity-70">
      {/* First gradient */}
      <div
        className="absolute w-full h-full opacity-90"
        style={{
          background: `radial-gradient(circle at 50% 50%, ${color1} 0%, transparent 65%, transparent 100%)`,
          bottom: '-350px',
          right: '-150px',
          filter: 'blur(60px)',
          animation: 'drift-one 5s ease-in-out infinite alternate-reverse'
        }}
      />
      {/* Second gradient */}
      <div
        className="absolute w-full h-full opacity-90"
        style={{
          background: `radial-gradient(circle at 50% 50%, ${color2} 0%, transparent 65%, transparent 100%)`,
          bottom: '-400px',
          right: '0px',
          filter: 'blur(60px)',
          animation: 'drift-two 10s ease-in-out infinite alternate-reverse'
        }}
      />
      {/* Third gradient */}
      <div
        className="absolute w-full h-full opacity-90"
        style={{
          background: `radial-gradient(circle at 50% 50%, ${color3} 0%, transparent 65%, transparent 100%)`,
          bottom: '-500px',
          right: '150px',
          filter: 'blur(60px)',
          animation: 'drift-three 15s ease-in-out infinite alternate-reverse'
        }}
      />
      <style jsx global>{`
        @keyframes drift-one {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(-40px, -40px);
          }
        }
        @keyframes drift-two {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(40px, -60px);
          }
        }
        @keyframes drift-three {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(-60px, 40px);
          }
        }
      `}</style>
    </div>
  );
};

export default AnimatedGradient;
