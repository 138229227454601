import TickerLink from '@/components/ui/Elements/TickerLink';

export default function SocialProof() {
  const msh_ids = [
    'COM:GERMANBIONIC',
    'NYSE:JPM',
    // 'EDU:CORNELL',
    'COM:APPLE',
    'EDU:YALE',
    'NYSE:BAC',
    'COM:AMAZON'
  ];

  return (
    <div className=" content-default ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center opacity-70">
            <p className="mt-4 text-sm mx-auto">
              used by individuals and teams at some of the world's most
              innovative companies and institutions
            </p>
          </div>
          <div className="marquee-wrapper ">
            <div className="marquee">
              <div className=" flex items-center justify-center gap-x-5 py-5  divide-line marquee__group">
                {msh_ids.map((id) => (
                  <div className="px-5 py-2 panel ">
                    <TickerLink key={id} msh_id={id} large={true} />
                  </div>
                ))}
              </div>
              <div
                className=" flex items-center justify-center gap-x-5 py-5  divide-line marquee__group"
                aria-hidden="true"
              >
                {msh_ids.map((id) => (
                  <div className="px-5 py-2 panel ">
                    <TickerLink key={id} msh_id={id} large={true} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
