import classNames from 'classnames';

import { useEffect, useRef, useState } from 'react';
import { spring, presets, TransitionMotion } from 'react-motion';
import TickerLink from '../../Elements/TickerLink';
import NextGenTickerTable from '../NextGenTickerTable';

export const questions = [
  // Existing ones
  {
    text: 'Identify large startups currently expanding in the European market',
    icon: 'ExpansionIcon',
    schedule: 'Quarterly',
    textClasses: 'text-blue-600',
    classNames: 'bg-gradient-to-tr from-blue-500 to-green-600',
    entries: [
      {
        msh_id: 'NYSE:SPOT',
        explanation:
          'Spotify is broadening its European operations with new regional offices and expanding its podcasting services, particularly focusing on markets in Eastern Europe.'
      },
      {
        msh_id: 'COM:BYTEDANCE',
        explanation:
          'ByteDance, the parent company of TikTok, is increasing its presence in Europe by establishing new data centers and offices in Ireland and the Netherlands to support its growing user base.'
      },
      {
        msh_id: 'COM:ADYEN',
        explanation:
          'Adyen, a global payment company, is expanding its services across Europe, enhancing its local presence with new offices and partnerships in several key markets.'
      }
    ]
  },
  {
    text: 'Find companies that have recently invested in foundational AI startups',
    icon: 'StartupIcon',
    schedule: 'Quarterly',
    textClasses: 'text-orange-600',
    classNames: 'bg-gradient-to-tr from-orange-500 to-yellow-600',
    entries: [
      {
        msh_id: 'COM:A16Z',
        explanation:
          "Andreessen Horowitz recently led a €385 million Series A for Mistral AI, an open-source large language model creator, and invested in Inceptive's $100 million AI drug discovery round."
      },
      {
        msh_id: 'COM:SEQUOIACAP',
        explanation:
          'Sequoia Capital has been actively investing in AI, including a significant round in Cohere, a startup developing language models, and a $100 million investment in Inflection AI.'
      },
      {
        msh_id: 'NASDAQ:MSFT',
        explanation:
          'Microsoft continues to invest heavily in AI startups, notably leading a $300 million funding round for OpenAI, enhancing their strategic partnership and furthering AI advancements.'
      }
    ]
  },

  {
    text: 'Identify drug companies who have recently announced late-stage cancer therapies',
    icon: 'CancerTherapyIcon',
    schedule: 'Quarterly',
    textClasses: 'text-red-600',
    classNames: 'bg-gradient-to-tr from-red-500 to-pink-600',
    entries: [
      {
        msh_id: 'COM:PFIZER',
        explanation:
          'Pfizer recently announced positive results from its Phase III trial of a new breast cancer therapy, showing significant improvement in progression-free survival rates.'
      },
      {
        msh_id: 'COM:ROCHE',
        explanation:
          'Roche has revealed promising outcomes from its late-stage study of a novel lung cancer treatment, demonstrating enhanced efficacy compared to existing therapies.'
      },
      {
        msh_id: 'NYSE:MRK',
        explanation:
          "Merck's recent late-stage clinical trial for an advanced melanoma therapy has shown a substantial increase in overall survival rates, marking a breakthrough in treatment options."
      }
    ]
  }
];

const windowSize = 1;
export default function ResearchHeader() {
  const [stack, setStack] = useState<any[]>([]);
  const [headerItemIndex, setHeaderItemIndex] = useState(0);
  const [currentContent, setCurrentContent] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [typingDone, setTypingDone] = useState(false);
  const [startWaiting, setStartWaiting] = useState(false);
  const [offsets, setOffsets] = useState([]);
  const refs = Array.from({ length: 5 }, () => useRef<HTMLLIElement>(null));
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if (!isVisible) return;
    const typeInterval = setInterval(() => {
      if (charIndex < questions[headerItemIndex].text.length) {
        setCurrentContent(
          questions[headerItemIndex].text.slice(0, charIndex + 1)
        );
        setCharIndex((prevCharIndex) => prevCharIndex + 1);
      } else {
        setStartWaiting(true);
        setTimeout(() => {
          setStartWaiting(false);
          clearInterval(typeInterval);
          setTypingDone(true);
        }, 4000);
      }
    }, 40);

    return () => {
      clearInterval(typeInterval);
    };
  }, [headerItemIndex, charIndex, isVisible]);
  const stackRef = useRef(stack);
  stackRef.current = stack;
  const headerItemRef = useRef(headerItemIndex);
  headerItemRef.current = headerItemIndex;

  useEffect(() => {
    if (!isVisible) return;
    if (!typingDone) {
      return;
    }

    let timeoutChange;
    const interval = setInterval(
      () => {
        // add one item from current header item 'entrys' to stack

        if (
          stackRef.current.length <
          questions[headerItemRef.current].entries.length
        ) {
          setStack((prevStack) => {
            const nextIndex = prevStack.length;
            if (nextIndex < questions[headerItemRef.current].entries.length) {
              return [
                questions[headerItemRef.current]?.entries[nextIndex],
                ...prevStack
              ];
            }
            return prevStack;
          });
        } else {
          timeoutChange = setTimeout(
            () => {
              setHeaderItemIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % questions.length;
                return nextIndex;
              });
              setStack([]);
              setCharIndex(0);
              setTypingDone(false);
              setCurrentContent('');
            },
            Math.random() * 1400 + 1000
          );
        }
      },
      Math.random() * 1400 + 3000
    );
    return () => {
      clearTimeout(timeoutChange);
      clearInterval(interval);
    };
  }, [typingDone, isVisible]);

  const getStyles = () => {
    const cummulativeOffset = 0;
    return stack.map((d, i) => {
      const offset = refs
        .slice(0, i)
        .reduce((a, c) => a + (c?.current?.offsetHeight || 0), 0);
      return {
        key: `spin-it-${d?.msh_id}`,
        data: d,
        style: {
          opacity: spring(1 - i * (1 / 3), presets.gentle),
          translateY: spring(offset, presets.gentle),
          scale: spring(1, presets.gentle)
        }
      };
    });
  };

  const willEnter = () => {
    return {
      opacity: 1,
      scale: 0.1,
      translateY: 0
    };
  };

  const willLeave = () => {
    return {
      opacity: 0,
      scale: 0,
      translateY: 0
    };
  };
  const allMshIds = questions.reduce((acc, val) => {
    return acc.concat((val.entries as any).map((d) => d.msh_id));
  }, []);

  return (
    <div className="w-full max-w-xl mx-auto " ref={containerRef}>
      <div className="hidden">
        <NextGenTickerTable tickers={allMshIds} />
      </div>

      <div className="gap-x-10 gap-y-3 w-full">
        <div
          // style={{ opacity: style.opacity }}
          className={classNames(
            ` gap-10 relative w-full p-5 ${typingDone ? 'slide-iaaan' : ''}`
          )}
        >
          <div className=" gap-y-1 w-full relative">
            <div className="border-line border mb-2 rounded-lg p-4  font-medium w-full panel-bg ">
              {currentContent}
              {(startWaiting || typingDone) && (
                <span className="blinking-cursor"></span>
              )}
            </div>
          </div>
          <TransitionMotion
            styles={getStyles()}
            willLeave={willLeave}
            willEnter={willEnter}
          >
            {(styles) => {
              return (
                <ul className="todo-list   relative overflow-hidden p-5 h-64 ">
                  <div
                    className={classNames(
                      ' top-0 left-0 right-0 pt-10 absolute flex items-center justify-center',
                      {
                        'opacity-0': !startWaiting,
                        'opacity-100': startWaiting && stack.length === 1
                      }
                    )}
                  >
                    <div className=" bg-lime-500 animate-ping  top-10 left-1/2  w-10 h-10 rounded-full"></div>
                  </div>
                  {/* <div className="absolute bottom-0 w-full left-0 h-10 z-20 bg-gradient-to-t from-main-background via-main-background to-transparent "></div> */}
                  {styles.map(({ key, style, data }, i) => (
                    <li
                      key={key}
                      ref={refs[i]}
                      style={{
                        transform: `translateY(${style.translateY}px) scale(${style.scale})`,
                        opacity: style.opacity
                      }}
                      className="absolute overflow-hidden  w-[90%] pl-2 py-1 max-w-lg mx-auto "
                    >
                      <div className="p-3 rounded-lg panel-bg border border-line text-lg shadow-sm  justify-start">
                        <div className="block leading-none mb-2">
                          <TickerLink msh_id={data.msh_id} large={false} />
                        </div>
                        <div className="border-b border-line mb-2"></div>
                        <h4
                          className={classNames(
                            ' text-xs font-semibold mb-1',
                            data.textClasses
                          )}
                        >
                          {data.explanation}
                        </h4>
                      </div>
                    </li>
                  ))}
                </ul>
              );
            }}
          </TransitionMotion>
        </div>
        {/* )}
        </Motion> */}
      </div>
    </div>
  );
}
