// import Link from 'next/link';
// import s from './Footer.module.css';

import { Linkedin, X } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useUiStore } from '@/store/ui';

// import Logo from '@/components/icons/Logo';
// import GitHub from '@/components/icons/GitHub';

const navigation = {
  page: [
    { name: 'Home', href: '/' },
    // { name: 'Platform', href: '/' },
    // { name: 'Singals', href: '/l/signals' },
    { name: 'API', href: '/l/api' },
    { name: 'News API', href: '/l/news' },
    { name: 'Faq', href: '/faq' },
    { name: 'Dashboard', href: '/home' }
  ],
  support: [
    { name: 'Contact', href: '/contact' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'API Status', href: 'https://status.markets.sh', external: true },
    { name: 'API Docs', href: '/docs/api' },
    { name: 'Submit to index', href: '/lists/requests' }
  ],
  // company: [
  //   { name: 'About', href: '/about' }
  //   // { name: 'Blog', href: '/blog' }
  //   // { name: 'Jobs', href: '/jobs' }
  //   // { name: 'Press', href: '/signin' },
  //   // { name: 'Partners', href: '/signin' }
  // ],
  legal: [
    { name: 'Legal', href: '/legal' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Imprint', href: '/imprint' }
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/markets-sh/',
      icon: Linkedin
    },

    {
      name: '𝕏',
      href: 'https://x.com/markets_sh',
      icon: X
    }
    // {
    //   name: 'App Store',
    //   href: 'Google',
    //   icon:
    // }
  ]
} as {
  [key: string]: {
    name: string;
    href: string;
    icon?: (props: any) => JSX.Element;
    external?: boolean;
  }[];
};

export default function Footer() {
  const router = useRouter();
  const ui = useUiStore();
  if (router.pathname === '/chat') {
    return null;
  }
  return (
    <footer
      className={classNames(
        'relative bg-gradient-to-b from-neutral-100 to-neutral-200 dark:from-black dark:to-black py-10 z-20  border-t border-line',
        {
          'md:pl-52': ui.sidebar_pinned,
          'md:pl-14': !ui.sidebar_pinned
        }
      )}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="flex w-full justify-between">
          <div className="flex-col flex gap-y-5">
            <div>
              <p className=" text-sm opacity-80  md:mt-0 md:order-1">
                markets.sh GmbH
                <br />
                Gipsstr. 5 <br />
                10119 Berlin
                <br /> Germany
              </p>
            </div>
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target={item.external ? '_blank' : '_self'}
                  className=" hover:text-gray-300"
                  rel="noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  {item.icon && (
                    <item.icon
                      className="h-6 w-6 stroke-neutral-800 dark:stroke-neutral-400"
                      aria-hidden="true"
                    />
                  )}
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 xl:col-span-2 ">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-medium  tracking-wider ">Page</h3>
                <ul role="list" className="mt-4 space-y-2 ">
                  {navigation.page.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm opacity-70 hover:opacity-100 ">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-medium  tracking-wider ">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm opacity-70 hover:opacity-100 ">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-medium  tracking-wider ">Legal</h3>
                <ul role="list" className="mt-4 space-y-2">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm opacity-70 hover:opacity-100 ">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
