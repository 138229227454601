import { User } from '@supabase/gotrue-js';
import { Database } from '../types/supabase';
import { createBrowserClient } from '@supabase/ssr';

// Initialize the browser client
export const supabase = createBrowserClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  {
    // Browser clients don't need cookie configuration
    auth: {
      storageKey: process.env.NEXT_PUBLIC_COOKIE_NAME
    },
    cookieOptions: {
      name: process.env.NEXT_PUBLIC_COOKIE_NAME
    }
  }
);

// Define proper types for the products and prices
type ProductWithPrices = Database['public']['Tables']['products']['Row'] & {
  prices: Database['public']['Tables']['prices']['Row'][];
};

export const getActiveProductsWithPrices = async (): Promise<
  ProductWithPrices[]
> => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('metadata->>version', 'v2')
    .eq('prices.active', true)
    .order('unit_amount', { foreignTable: 'prices', ascending: false });

  if (error) {
    console.error('Error fetching products:', error.message);
    throw error;
  }

  if (!data) return [];

  // Sort products by their first price's unit amount
  return data.sort((a, b) => {
    const aAmount = a.prices[0]?.unit_amount ?? 0;
    const bAmount = b.prices[0]?.unit_amount ?? 0;
    return aAmount - bAmount;
  });
};

export const updateUserName = async (
  user: User,
  name: string
): Promise<void> => {
  const { error } = await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id);

  if (error) {
    console.error('Error updating user name:', error.message);
    throw error;
  }
};
