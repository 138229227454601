import React from 'react';

const CenterGradient = () => {
  return (
    <div className="relative inset-0 w-full h-full bg-red-500">
      {/* Container to center gradients */}
      <div className="absolute inset-0 w-full h-full">
        {/* Green gradient */}
        <div
          className="absolute top-1/2 left-1/2 w-[200%] h-[200%] -translate-x-1/2 -translate-y-1/2 opacity-90"
          style={{
            background:
              'radial-gradient(circle at center, #6aff00 0%, transparent 65%)',
            filter: 'blur(60px)',
            animation:
              'gradient-drift-one 5s ease-in-out infinite alternate-reverse'
          }}
        />
        {/* Light blue gradient */}
        <div
          className="absolute top-1/2 left-1/2 w-[200%] h-[200%] -translate-x-1/2 -translate-y-1/2 opacity-90"
          style={{
            background:
              'radial-gradient(circle at center, #00a2ff 0%, transparent 65%)',
            filter: 'blur(60px)',
            animation:
              'gradient-drift-two 10s ease-in-out infinite alternate-reverse'
          }}
        />
        {/* Dark blue gradient */}
        <div
          className="absolute top-1/2 left-1/2 w-[200%] h-[200%] -translate-x-1/2 -translate-y-1/2 opacity-90"
          style={{
            background:
              'radial-gradient(circle at center, #413ace 0%, transparent 65%)',
            filter: 'blur(60px)',
            animation:
              'gradient-drift-three 15s ease-in-out infinite alternate-reverse'
          }}
        />
      </div>

      <style jsx global>{`
        @keyframes gradient-drift-one {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translate(-50%, -50%) translate(-40px, -40px);
          }
        }
        @keyframes gradient-drift-two {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translate(-50%, -50%) translate(40px, -60px);
          }
        }
        @keyframes gradient-drift-three {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translate(-50%, -50%) translate(-60px, 40px);
          }
        }
      `}</style>
    </div>
  );
};

export default CenterGradient;
