import { useLoading } from '@/hooks/useLoading';
import { useNotification } from '@/hooks/useNotification';
import { supabase } from '@/utils/supabase-client';
import { useUser } from '@/utils/useUser';
import { CheckCircle2, PlusCircle } from 'lucide-react';
import { memo, useEffect, useState } from 'react';

interface QuickFollowButtonProps {
  msh_id: string;
  last_price?: number;
  watchlistMode?: boolean;
}
const QuickFollowButton = ({ msh_id, last_price }: QuickFollowButtonProps) => {
  const { user, watchlist } = useUser();
  const [isFollowing, setIsFollowing] = useState<boolean | null>(null);
  const notification = useNotification();
  const [initialLoading, setInitialLoading] = useState(true);

  const loading = useLoading();

  useEffect(() => {
    if (!user || !watchlist) {
      return;
    }
    setIsFollowing(
      watchlist?.map((item) => item.msh_id).includes(msh_id) || false
    );
    setInitialLoading(false);
  }, [msh_id, watchlist, JSON.stringify(watchlist)]);

  const addToWatchlist = (ticker: string | undefined) => {
    loading.start();
    supabase
      .from('msh_watchlists')
      .insert([
        {
          msh_id: msh_id,
          avg_price: last_price
        }
      ])
      .then(() => {
        notification.showNotification({
          message: `${ticker} added to watchlist`
        });
        setIsFollowing(true);
        loading.done();
      });
  };
  const deleteFromWatchlist = async (ticker: string | undefined) => {
    if (!ticker || !user) {
      return;
    }
    loading.start();
    return supabase
      .from('msh_watchlists')
      .delete()
      .match({ msh_id: msh_id })
      .then(() => {
        notification.showNotification({
          message: `${ticker} removed from watchlist`
        });

        setIsFollowing(false);
        loading.done();
      });
  };

  if (!user) {
    return null;
  }

  return (
    <div
      className=" shrink-0 flex items-center justify-center text-sm h-full"
      style={initialLoading ? { opacity: 0 } : { opacity: 1 }}
    >
      {isFollowing === false ? (
        <button
          type="button"
          title="Add to watchlist"
          className="inline-flex items-center opacity-50 hover:opacity-100 "
          onClick={() => addToWatchlist(msh_id)}
        >
          <PlusCircle size={20} strokeWidth={1.5} />
        </button>
      ) : (
        <button
          type="button"
          title="Remove from watchlist"
          className="text-white bg-blue-500 dark:bg-transparent dark:text-blue-500 inline-flex items-center opacity-95 hover:opacity-100 rounded-full "
          onClick={() => deleteFromWatchlist(msh_id)}
        >
          <CheckCircle2 size={20} />
        </button>
      )}
    </div>
  );
};
const MemoFollowButton = memo(
  QuickFollowButton,
  (props, newprops) => props.msh_id == newprops.msh_id
);

MemoFollowButton.displayName = 'MemoFollowButton';
export default MemoFollowButton;
