import LandingLayout from '@/components/LandingLayout';
import FooterCta from '@/components/ui/PageBlocks/Landing/FooterCta';
import MainFeatures from '@/components/ui/PageBlocks/Landing/Home/MainFeatures';
import SalesHero from '@/components/ui/PageBlocks/Landing/Home/SalesHero';
import Subhead from '@/components/ui/PageBlocks/Landing/Home/Subhead';
// import SocialProof from '@/components/ui/PageBlocks/Landing/Home/SocialProof';
import AnimatedGradient from '@/components/ui/PageBlocks/Landing/Home/AnimatedGradient';
import CenterGradient from '@/components/ui/PageBlocks/Landing/Home/CenterGradient';
import DataBento from '@/components/ui/PageBlocks/Landing/Home/DataBento';
import SocialProof2 from '@/components/ui/PageBlocks/Landing/Home/SocialProof2';
import { getActiveProductsWithPrices } from '@/utils/supabase-client';
import Head from 'next/head';
import { ReactElement, useEffect, useRef } from 'react';
import { Gradient } from 'stripe-gradient';

export const getStaticProps = async () => {
  const products = await getActiveProductsWithPrices();

  return {
    props: {
      products: products
    }
  };
};
export function New({ products }) {
  const planeDevRef = useRef(null);
  useEffect(() => {
    new Gradient({
      canvas: '#my-canvas-id',
      colors: ['#a960ee', '#ff333d', '#90e0ff', '#ffcb57']
    });
  }, []);
  return (
    <>
      <Head>
        <title>markets.sh </title>
      </Head>
      {/* <div className="absolute top-0 right-0 overflow-hidden   z-10 w-1/2  h-full"></div> */}
      <SalesHero />
      <div className="relative  ">
        <div className="absolute h-full w-full left-0  top-0  dark:opacity-5 opacity-5 overflow-hidden">
          <CenterGradient />
        </div>
        <div className="relative top-0 landing-page-wrap   z-10 pb-10">
          <div className="  left-0 z-20 w-full flex flex-col py-5 items-center border-t border-line">
            <div className="w-full mb-4 hidden md:block pt-0 ">
              <SocialProof2 />
            </div>
            {/* <ChevronDown className="opacity-50" size={50} strokeWidth={1} /> */}
          </div>
          <DataBento />
        </div>
        {/* <Blair /> */}

        <MainFeatures />
      </div>
      <div className="relative top-0 landing-page-wrap  bg-landing-neutral  pb-default pt-10 border-t border-line">
        {/* <Process /> */}
        {/* <ChatBenefits /> */}
        {/* <TechBenefits /> */}
        <Subhead />
        <div className="mb-10" />
        <FooterCta />
        <div className="absolute h-screen w-full  bottom-0  opacity-50">
          <AnimatedGradient />
        </div>
        <div className="mb-20" />
        <div className="px-5"></div>
      </div>
    </>
  );
}

New.getMainLayout = (page: ReactElement) => {
  // Todo: Make the header a nice gradient backdrop with the color of the stock?
  return <LandingLayout>{page}</LandingLayout>;
};

export default New;
